import React, { useState, useRef } from 'react';

export default function DotCycle({ isIndex, value }) {
    const [isActive, setIsActive] = useState(false);
    const iRef = useRef(null);

    function handleHover() {
        const className = iRef.current;
        if (!isActive) {
            className.classList.toggle('hover');
        } else {
            className.classList.remove('hover');
        }
    }

    function handleHighlight() {
        if (isIndex == value) {
            return '#8a187b';
        }
    }

    return (
        <>
            <i
                className="fa fa-minus"
                aria-hidden="true"
                style={{ filter: 'brightness(1.5)', color: handleHighlight() }}
                ref={iRef}
                onPointerEnter={() => {
                    setIsActive(true);
                    handleHover();
                }}
                onPointerLeave={() => {
                    setIsActive(false);
                    handleHover();
                }}
            ></i>
        </>
    );
}
