import React from 'react';
import { useState } from 'react';

export default function MainMessageForm() {
    const [inputTxt, setInputTxt] = useState({
        userName: 'Name',
        userEmail: 'Email',
        userMsg: 'Your message',
    });

    function handleTxtInputChange(e) {
        setInputTxt({
            ...inputTxt,
            [e.target.name]: e.target.value,
        });
    }
    return (
        <>
            <form className="msgForm" action="" method="">
                <fieldset>
                    <legend>Tell us your thoughts</legend>
                    <label for="name">
                        <input
                            className="msgInput"
                            type="text"
                            id="name"
                            value={inputTxt.userName}
                            name="userName"
                            onChange={handleTxtInputChange}
                            required
                        />
                    </label>

                    <label for="mail">
                        <input
                            className="msgInput"
                            type="email"
                            id="mail"
                            value={inputTxt.userEmail}
                            name="userEmail"
                            onChange={handleTxtInputChange}
                            required
                        />
                    </label>
                    <label for="msg">
                        <textarea
                            type="text"
                            id="msg"
                            value={inputTxt.userMsg}
                            onChange={handleTxtInputChange}
                            name="userMsg"
                        />
                    </label>
                    <button type="submit">Send</button>
                </fieldset>
            </form>
        </>
    );
}
