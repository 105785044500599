import React from 'react';
import { useState, useEffect, useRef } from 'react';
import DotCycle from './cycleDotComponent';
import MainSlides from './SlideComponent';

export default function Slide() {
    const [index, setIndex] = useState(0);
    const counterRef = useRef(0);
    const endOfRole = 3;

    //useEffect will be called upon page loading.
    useEffect(() => {
        const increment = () => {
            //use .current property of useRef Hook
            counterRef.current = counterRef.current + 1;
            if (counterRef.current < endOfRole) {
                // using a updater function to update the index variable
                setIndex((i) => i + 1);
            } else {
                setIndex((i) => i * 0);
                counterRef.current = 0;
            }
        };
        //return the setInterval ID to the on the intervalid variable
        const intervalId = setInterval(increment, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            <MainSlides index={index} />
            <section>
                <div className="slide-a-show">
                    <DotCycle isIndex={index} value={0} />
                    <DotCycle isIndex={index} value={1} />
                    <DotCycle isIndex={index} value={2} />
                </div>
            </section>
        </>
    );
}
