import React, {
    useState,
    useEffect,
    useRef,
    createContext,
    useContext,
} from 'react';
import { navDrpDnList } from './navItems.jsx';
import { NavBarLi } from './smallViewNavEffect.jsx';

export const createDrpDwnTxt = createContext(null);

export default function NavBar() {
    const showNav = navDrpDnList;
    const [isDisplay, setIsDisplay] = useState(false);
    const ref = useRef(null);
    const firstProperty = showNav[0];
    const propertyId = firstProperty.listId;

    const onBodyClick = () => {
        setIsDisplay((state) => !state);
    };

    //useEffect applied a click listening event to the document body upon DOM loading.
    useEffect(() => {
        const handleNavListDisplay = (e) => {
            //check to see if the element of it's children contain on DOM element
            if (ref.current && !ref.current.contains(e.target)) {
                isDisplay && onBodyClick();
                document.body.style.overflow = isDisplay ? 'visible' : 'hidden';
            }
        };
        document.addEventListener('click', handleNavListDisplay);
        //react clean-up code with a DOM API function. This code remove the event listener and reset the useEffect.
        return () => {
            document.removeEventListener('click', handleNavListDisplay);
        };
        //The useEffect dependency is set the useState state.
    }, [isDisplay]);

    return (
        <>
            <nav
                aria-labelledby="primary-navigation"
                id="primary-navigation"
                className="nav-wrap"
            >
                <div
                    className="hamburgerMenu"
                    onClick={(e) => {
                        onBodyClick();
                        document.body.style.overflow = isDisplay
                            ? 'visible'
                            : 'hidden';
                        //call stopProgation to prevent the click event bubbling up to the body
                        e.stopPropagation();
                    }}
                >
                    <a href="#" className="menuIcon" aria-label="label">
                        <i className="fas fa-bars"></i>
                    </a>
                </div>
                <div
                    //use of temperal literal to change the className base upon the isDisplay variable
                    className={`header-nav ${isDisplay ? 'active' : ''}`}
                    ref={ref}
                >
                    <ul className="nav-list">
                        <li id="homeIcon">
                            <a id="home-icon" href="index.html">
                                Home
                            </a>
                        </li>
                        {propertyId.map((id, index) => (
                            <createDrpDwnTxt.Provider
                                value={{ isDisplay, setIsDisplay }}
                            >
                                <NavBarLi
                                    listByID={showNav}
                                    id={id}
                                    key={index}
                                />
                            </createDrpDwnTxt.Provider>
                        ))}
                    </ul>
                </div>
            </nav>
        </>
    );
}

export function usecreateDrpDwnTxt() {
    return useContext(createDrpDwnTxt);
}
