export default [
    {
        mainClass: 'role-cycle transition-slide-1',
        groupClass: 'role-cycle-element-group',
        elementClass: 'element-desrcpWrper-h1',
        rFontClass: 'R1-font',
        h1Class: 'Take control of your destination. Fly with Us!',
        h1DevClass: 'ln-btn-hov',
        pClass: 'learn more',
    },
    {
        mainClass: 'role-cycle transition-slide-2',
        groupClass: 'role-cycle-element-group',
        elementClass: 'element-desrcpWrper-h1',
        rFontClass: 'R2-font',
        h1Class: 'Fly more for a lot Less.',
        h1DevClass: 'ln-btn-hov',
        pClass: 'learn more',
    },
    {
        mainClass: 'role-cycle transition-slide-3',
        groupClass: 'role-cycle-element-group',
        elementClass: 'element-desrcpWrper-h1',
        rFontClass: 'R3-font',
        h1Class: 'Come join us at our beautiful aerodrome.',
        h1DevClass: 'ln-btn-hov',
        pClass: 'learn more',
    },
]
