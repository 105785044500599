import React, { useState, useEffect, useRef, useContext } from 'react';
import { usecreateDrpDwnTxt } from './navBarList';
import { ImgGenCntrl } from './ImgGenContrl';
import list from 'postcss/lib/list';

export function NavBarLi({ listByID, id, index }) {
    const { isDisplay } = usecreateDrpDwnTxt();
    const [displayDrpDwn, setDisplayDrpDwn] = useState(false);
    const ref = useRef('');
    const mainNav = listByID[id];
    const drpdwnNav = mainNav.listId;

    const handleDrpDwnState = () => {
        const drpDwnElemArry = document.querySelectorAll('.dropdown-list');
        drpDwnElemArry.forEach((lst) => {
            if (lst.id == ref.current.id) {
                lst.style.display =
                    lst.style.display == 'none' ? 'block' : 'none';
                lst.classList.toggle('show');
                setDisplayDrpDwn((state) => !state);
            } else {
                lst.style.display = 'none';
                lst.className = 'dropdown-list';
            }
        });
    };
    useEffect(() => {
        const handleScreenchange = () => {
            window.screen.width > 980
                ? (ref.current.style.display = 'block')
                : (ref.current.style.display = 'none');
        };
        window.addEventListener('load', handleScreenchange);
        window.addEventListener('resize', handleScreenchange);

        return () => {
            window.removeEventListener('resize', handleScreenchange);
            window.removeEventListener('load', handleScreenchange);
        };
    });

    return (
        <>
            <li key={index}>
                <a href={mainNav.href}>{mainNav.title}</a>
                {ref.current.classList ? (
                    <ImgGenCntrl
                        className={ref.current}
                        onClick={handleDrpDwnState}
                        id={id}
                        key={index}
                    />
                ) : null}
                {drpdwnNav.length > 0 && (
                    <ul
                        className="dropdown-list"
                        ref={ref}
                        id={id}
                        style={{ display: isDisplay ? 'none' : '' }}
                    >
                        {drpdwnNav.map((dpid, index) => (
                            <NavBarLi
                                listByID={listByID}
                                id={dpid}
                                key={index}
                            />
                        ))}
                    </ul>
                )}
            </li>
        </>
    );
}
