import React, { forwardRef, useEffect } from 'react';
import { SlArrowRight, SlArrowDown } from 'react-icons/sl';
import icons8forward48 from '/img/Header-socialPhoto/icons8forward48.png';
import icons8expandarrow48 from '/img/Header-socialPhoto/icons8expandarrow48.png';
import { usecreateDrpDwnTxt } from './navBarList';

export const ImgGenCntrl = ({ onClick, className, id, key }) => {
    const { isDisplay } = usecreateDrpDwnTxt();
    const handleimgState = (e) => {
        const imgElemArry = document.querySelectorAll('.arrow');
        imgElemArry.forEach((el) => {
            if (el.id == id) {
                el.src = className.classList.contains('show')
                    ? icons8expandarrow48
                    : icons8forward48;
            } else {
                el.src = icons8forward48;
            }
        });
    };

    return (
        <img
            key={key}
            className="arrow"
            id={id}
            src={isDisplay ? icons8forward48 : ''}
            onClick={(e) => {
                onClick();
                handleimgState(e);
                e.stopPropagation();
            }}
        />
    );
};
