import React from 'react';

export default function Gallery() {
    return (
        <h1>
            <a href="#" style={{ color: 'aliceblue' }}>
                Our Gallery
            </a>
        </h1>
    );
}
