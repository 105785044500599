export const navDrpDnList = {
    0: {
        id: 0,
        title: '(Root)',
        href: '',
        alt: '',
        key: 0,
        listId: [2, 3, 10, 13, 19],
    },
    2: {
        id: 2,
        title: 'Maintenance',
        href: '#',
        alt: '',
        key: 2,
        listId: [],
    },
    3: {
        id: 3,
        title: 'Flights',
        href: 'Flights/index.html',
        alt: 'Image of an greater than arrow for navigation',
        key: 3,
        listId: [4, 5, 6, 7, 8, 9],
    },
    4: {
        id: 4,
        title: 'Discovery Flights',
        href: 'Flights/index.html#articledescpt-h1_1',
        alt: '',
        key: 4,
        listId: [],
    },
    5: {
        id: 5,
        title: 'Summer Programs',
        href: 'Flights/index.html#articledescpt-h1_2',
        alt: '',
        key: 5,
        listId: [],
    },
    6: {
        id: 6,
        title: 'Flight with a Pilot',
        href: 'Flights/index.html#articledescpt-h1_3',
        alt: '',
        key: 6,
        listId: [],
    },
    7: {
        id: 7,
        title: 'Pilot Hub',
        href: 'Flights/index.html#articledescpt-h1_4',
        alt: '',
        key: 7,
        listId: [],
    },
    8: {
        id: 8,
        title: 'Visiting an Aircraft',
        href: 'Flights/index.html#articledescpt-h1_5',
        alt: '',
        key: 8,
        listId: [],
    },
    9: {
        id: 9,
        title: 'Join Today!',
        href: 'href="log&SgnComp/signup-area/signup-app_index.html',
        alt: '',
        key: 9,
        listId: [],
    },
    10: {
        id: 10,
        title: 'Rates',
        href: 'Rates/index.html',
        alt: 'Image of an greater than arrow for navigation',
        key: 10,
        listId: [11, 12],
    },
    11: {
        id: 11,
        title: 'Wet Rate',
        href: '../Rates/index.html#rteArticledescpt-h1_1',
        alt: '',
        key: 11,
        listId: [],
    },
    12: {
        id: 12,
        title: 'Dry Rate',
        href: '../Rates/index.html#rteArticledescpt-h1_2',
        alt: '',
        key: 12,
        listId: [],
    },
    13: {
        id: 13,
        title: 'Members Area',
        href: 'Members_area/index.html',
        alt: 'Image of an greater than arrow for navigation',
        key: 13,
        listId: [14, 15, 16, 17, 18],
    },
    14: {
        id: 14,
        title: 'Becoming a Member',
        href: '../Members_area/index.html#memArticledescpt-h1_1',
        alt: '',
        key: 14,
        listId: [],
    },
    15: {
        id: 15,
        title: 'Flight Planning',
        href: '../Members_area/index.html#memArticledescpt-h1_2',
        alt: '',
        key: 15,
        listId: [],
    },
    16: {
        id: 16,
        title: 'Facilities and Services',
        href: '../Members_area/index.html#memArticledescpt-h1_3',
        alt: '',
        key: 16,
        listId: [],
    },
    17: {
        id: 17,
        title: "Members ' Cirlce",
        href: '../Members_area/index.html#memArticledescpt-h1_4',
        alt: '',
        key: 17,
        listId: [],
    },
    18: {
        id: 18,
        title: 'Join Today!',
        href: '../log&SgnComp/signup-area/signup-app_index.html',
        alt: '',
        key: 18,
        listId: [],
    },
    19: {
        id: 19,
        title: 'About',
        href: 'About/index.html',
        alt: 'Image of an greater than arrow for navigation',
        key: 19,
        listId: [20, 21, 22, 23, 24],
    },
    20: {
        id: 20,
        title: 'Aircraft Fleet',
        href: '../About/index.html#abtArticledescpt-h1_1',
        alt: '',
        key: 20,
        listId: [],
    },
    21: {
        id: 21,
        title: 'Gallery',
        href: '../About/index.html#abtArticledescpt-h1_2',
        alt: '',
        key: 21,
        listId: [],
    },
    22: {
        id: 22,
        title: 'Airport, Aerospace & Weather',
        href: 'About/index.html#abtArticledescpt-h1_3',
        alt: '',
        key: 22,
        listId: [],
    },
    23: {
        id: 23,
        title: 'Forms!',
        href: '../About/index.html#abtArticledescpt-h1_4',
        alt: '',
        key: 23,
        listId: [],
    },
    24: {
        id: 24,
        title: 'FQAs',
        href: '../About/index.html#abtArticledescpt-h1_5',
        alt: '',
        key: 24,
        listId: [],
    },
};
