import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom/client';
import Slide from './mnPgComp/roleCycleComponent.jsx';
import Gallery from './mnPgComp/galleryComponent.jsx';
import MainMessageForm from './mnPgComp/FooterContactForm.jsx';
import NavBar from './NavBarComp/navBarList.jsx';

// Render your React component instead
ReactDOM.createRoot(document.getElementById('App')).render(
    <React.StrictMode>
        <Slide />
    </React.StrictMode>
);

ReactDOM.createRoot(document.getElementById('GalleryApp')).render(
    <React.StrictMode>
        <Gallery />
    </React.StrictMode>
);

ReactDOM.createRoot(document.getElementById('ContactApp')).render(
    <React.StrictMode>
        <MainMessageForm />
    </React.StrictMode>
);
ReactDOM.createRoot(document.getElementById('NavApp')).render(<NavBar />);
