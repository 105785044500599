import React from 'react';
import cssStylingAtt from './cssStylingAtt';

export default function MainSlides({ index }) {
    const cssStylingAtts = cssStylingAtt[index];

    return (
        <>
            <div className={cssStylingAtts.mainClass}>
                <div className="slide-wrapper">
                    <div className="space-div-box-1"></div>
                    <div className={cssStylingAtts.groupClass}>
                        <div className={cssStylingAtts.elementClass}>
                            <div className={cssStylingAtts.rFontClass}>
                                <h1>{cssStylingAtts.h1Class}</h1>
                                <div className={cssStylingAtts.h1DevClass}>
                                    <a href="#">
                                        <p>{cssStylingAtts.pClass}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
